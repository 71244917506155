<template>
  <app-dialog
    v-if="organizationWallet"
    v-model="model"
    :submitting="status === 'pending'"
    :title="title"
    :width="700"
    @submit="onAccept"
  >
    <div class="flex items-center gap-4 rounded bg-primary-50 p-4 text-primary">
      <app-icon icon="ph:coins" :size="30" />

      <div class="flex-auto">
        <p>
          Utilisez
          <strong>{{
            $t("credit.amount_label", { count: request.credits })
          }}</strong>
          sur <strong>{{ organizationWallet.balance }} disponibles</strong>
        </p>

        <p class="text-sm">
          Votre nouveau solde sera de
          {{
            $t("credit.amount_label", {
              count: organizationWallet.balance - request.credits,
            })
          }}
        </p>
      </div>

      <app-button type="submit"> Confirmer </app-button>
    </div>
  </app-dialog>
</template>

<script lang="ts" setup>
import type { RegistrationRequestView } from "~/business-areas/registration-request/registration-request.model";

const properties = defineProps<{
  modelValue?: boolean;
  request: RegistrationRequestView;
}>();

const model = useVModel(properties, "modelValue");

const { organizationWallet } = useCurrentOrganizationWalletStore();

const title = computed(
  () => `Confirmer la demande de ${properties.request.member.user.full_name}`,
);

const { close } = useDialog("RegistrationRequestAcceptationDialog");

const { refresh, status } = useLazyFetch(
  `/api/organizations/${properties.request.organization_id}/requests/${properties.request.id}/accept`,
  {
    method: "POST",
    immediate: false,
    watch: false,
    onResponseError({ error }) {
      useToastNotification().showError(
        error?.message ?? "Un problème est survenu durant la requête",
      );
    },
  },
);

const onAccept = async () => {
  await refresh();
  close({ confirmed: true });
};
</script>
